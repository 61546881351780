import {
  Box,
  Container,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Square,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby';

import { IconCircleCheck } from '@tabler/icons';
import React from 'react'

const LastSection = () => {

    const query = useStaticQuery(graphql`
    {
        wpPage(slug: {eq: "about"}) {
            about {
                lastTitle
                lastDescription
                lastImage {
                    sourceUrl
                }     
                whyOrderRepeater {
                    heading
                    text
                }
            }
        }
    }
    `)

    const data = query.wpPage.about

    return (
    <Box as="section" bg="gray.50">
        <Container py={20} px={28} maxWidth="100%">
            <HStack spacing={{ base: '12', md: '16' }} flexFlow={{base: "column", lg: "row"}}>
                <Stack spacing={{ base: '4', md: '5' }} pr="128px" pb={{base: "24px", lg: "0px"}} >
                    <Stack pb="4">
                        <Text fontSize="5xl" color="gray.800" fontWeight="700" lineHeight="48px" dangerouslySetInnerHTML={{__html: data.lastTitle}}></Text>
                    </Stack>
                    <Text color="gray.600" fontSize="20px" pb={16}>
                        {data.lastDescription}
                    </Text>
                        
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} columnGap={8} rowGap={9}>
                        {data.whyOrderRepeater.map((feature: any) => (
                        <Stack key={feature.name} spacing={{ base: '4', md: '5' }}>
                            <IconCircleCheck height="50px" width="50px" fill="#A38500" color="#F2F2F3"></IconCircleCheck>
                            <Stack spacing={{ base: '1', md: '2' }} flex="1">
                                <Text fontSize="18px" fontWeight="700" color="#A38500" >
                                    {feature.heading} 
                                </Text>
                                <Text color="gray.600" fontSize="16px" fontWeight="400" lineHeight="6">{feature.text}</Text>
                            </Stack>
                        </Stack>
                        ))}
                    </SimpleGrid>
                </Stack>

                <Image
                    src={data.lastImage.sourceUrl}
                    width={{base: "100%", lg:"544px"}}
                        height="648px"
                        marginInlineStart={{ base: "0px !important", lg: 12 }}
                        objectFit="cover"
                        objectPosition="left"
                />
            </HStack>
        </Container>
    </Box>
    )
}

export default LastSection
