import {
  Box,
  Container,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Square,
  Stack,
  Text,
  VStack,
  space,
} from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby';

import { IconCircleCheck } from '@tabler/icons';
import React from 'react'

const SectionOne = () => {

    const query = useStaticQuery(graphql`
    {
        wpPage(slug: {eq: "about"}) {
            about {
                textOne
                textThree
                textTwo
                imageOne {
                    sourceUrl
                }
                imageThree {
                    sourceUrl
                }
                imageTwo {
                    sourceUrl
                }
            }
        }
    }
    `)

    const data = query.wpPage.about

    return (
    <>
    <Box as="section" bg="white">
        <Container py={20} maxW="7xl">
            <HStack spacing={{ base: '12', md: '16' }} flexFlow={{base: "column-reverse", lg: "row"}} justifyContent="space-around">
        
                <Text width="544px" color="gray.600" fontSize="16px"  dangerouslySetInnerHTML={{__html: data.textOne}}></Text>

                <Image
                    src={data.imageOne.sourceUrl}
                    width="544px"
                    height="408px"
                    marginBottom={{base: "24px !important", lg: "0px !important"}}
                    marginInlineStart={{base: "0px !important", md: "4rem !important"}}
                />
            </HStack>
        </Container>
        </Box>
        
        <Box as="section" bg="gray.50">
            <Container py={20} maxW="7xl">
                    <HStack spacing={{ base: '12', md: '16' }} flexFlow={{ base: "column", lg: "row" }} justifyContent="space-around">
                        
                <Image
                    src={data.imageTwo.sourceUrl}
                            width="544px"
                            height="408px"
                    marginBottom={{base: "24px", lg: "0px"}}
                />
        
                <Text width="544px" color="gray.600" fontSize="16px" marginInlineStart={{base: "0px !important", lg: "16 !important"}} dangerouslySetInnerHTML={{__html: data.textTwo}}></Text>

                </HStack>
            </Container>
        </Box>

        <Box as="section" bg="white">
            <Container py={20} maxW="7xl">
                <HStack spacing={{ base: '12', md: '16' }} flexFlow={{base: "column-reverse", lg: "row"}} justifyContent="space-around">
        
                <Text width="544px" color="gray.600" fontSize="16px" dangerouslySetInnerHTML={{__html: data.textThree}}></Text>

                <Image
                    src={data.imageThree.sourceUrl}
                            width="544px"
                            height="408px"
                    marginBottom={{ base: "24px !important", lg: "0px !important" }}
                    marginInlineStart={{base: "0px !important", md: "4rem !important"}}
                />
                </HStack>
            </Container>
        </Box>
    </>
    )
}

export default SectionOne
