import * as React from "react"

import { Heading, Text, useToast } from '@chakra-ui/react';

import LastSection from "../components/About/LastSection";
import Layout from "../components/Base/Layout"
import {SEO} from '../components/Base/Seo'
import SectionOne from "../components/About/SectionOne";

const About = () => {

  return (
      <Layout> 
          <Text fontSize="4xl" fontWeight="700" textAlign="center" pt="80px" >About Us</Text>
          <SectionOne></SectionOne>
          <LastSection></LastSection>
      </Layout>
  )
}

export default About

export const Head = () => (
  <SEO title="AceMedical Wholesale | About Us" description="We are Ace Medical Wholesale, and we aim to improve the entire medical supply shopping experience for medical professionals like you" />
)
